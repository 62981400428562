<section
  @fade
  class="resume-section p-3 p-lg-5 d-flex align-items-center"
  id="about"
>
  <div class="w-100">
    <h1 class="mb-0">
      404
      <span class="text-primary">Page not found :(</span>
    </h1>
    <div class="subheading mb-5">
      The page you attempted to visit does not exist.
    </div>
    <p class="lead mb-5">
      You can start from the <a routerLink="/">home page</a>.
    </p>
  </div>
</section>

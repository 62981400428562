<div class="card mb-3 mr-3 custom-card-size">
  <div class="row no-gutters">
    <div class="col-4">
      <img [src]="textbook.imgUrl" class="card-img" [alt]="textbook.title" />
    </div>
    <div class="col-8">
      <div class="card-body">
        <h5 class="card-title">{{ textbook.title }}</h5>
        <p class="font-italic" style="font-size: 9pt">
          {{ textbook.titleInEnglish }}
        </p>
        <a [href]="textbook.pdfUrl" class="btn btn-outline-info">Read Online</a>
        <div class="card-text">
          <span
            class="badge badge-info mr-1"
            *ngFor="let topic of textbook.topics"
            >{{ topic }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

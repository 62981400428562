<section
  class="resume-section p-3 p-lg-5 d-flex align-items-center"
  id="experience"
  @fade
>
  <div class="w-100">
    <h2 class="mb-5">Experience</h2>

    <div
      class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
      *ngFor="let job of jobs | async"
    >
      <div class="resume-content">
        <h3 class="mb-0">{{ job.positionName }}</h3>
        <div class="subheading mb-3">{{ job.companyName }}</div>
        <p>{{ job.description }}</p>
        <div *ngIf="job.responsibilityDescription">
          <p [innerHTML]="job.responsibilityDescription"></p>
          <ul>
            <li *ngFor="let responsibility of job.responsibilities">
              {{ responsibility }}
            </li>
          </ul>
        </div>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary"
          >{{ job.startingDate }} - {{ job.endingDate }}</span
        >
      </div>
    </div>
  </div>
</section>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Job } from "../../models/job.model";
import { JobService } from "../../services/job.service";
import { getJobs } from "../state/jobs/jobs.actions";
import { selectJobs } from "../state/jobs/jobs.selectors";
import fadeAnimation from "../ui-util/animations/fade.animation";

@Component({
  selector: "app-experience",
  templateUrl: "./experience.component.html",
  styleUrls: ["./experience.component.css"],
  animations: [fadeAnimation],
})
export class ExperienceComponent implements OnInit, OnDestroy {
  public jobs: Observable<ReadonlyArray<Job>>;
  private jobSub: Subscription;

  constructor(private jobService: JobService, private store: Store) {
    this.jobs = this.store.select(selectJobs);
  }

  ngOnInit() {
    this.jobSub = this.jobService
      .getJobs()
      .subscribe((jobs: Job[]) => this.store.dispatch(getJobs({ jobs })));
  }

  ngOnDestroy() {
    this.jobSub.unsubscribe();
  }
}

<section @fade class="resume-section p-3 p-lg-5 d-flex align-items-center">
  <div class="w-100">
    <h1 class="mb-0">
      Ajzenhamer
      <span class="text-primary">Nikola</span>
    </h1>
    <div class="subheading mb-5">
      <a href="mailto:work@nikolaajzenhamer.rs">work@nikolaajzenhamer.rs</a>
    </div>
    <p class="lead mb-5">
      Currently employed as a Backend Engineer at <a href="https://www.proglove.com/" target="_blank">ProGlove</a>.
      My research interests include artificial intelligence, optimization, metaheuristics, graph theory & software
      development.
    </p>
    <div class="social-icons">
      <a href="https://www.linkedin.com/in/nikolaajzenhamer/">
        <i class="fab fa-linkedin-in"></i>
      </a>
      <a href="https://github.com/ajzenhamernikola/">
        <i class="fab fa-github"></i>
      </a>
    </div>
  </div>
</section>
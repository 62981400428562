<section
  @fade
  class="resume-section p-3 p-lg-5 d-flex align-items-center"
  id="education"
>
  <div class="w-100">
    <h2 class="mb-5">Education</h2>

    <div
      class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
      *ngFor="let education of educations"
    >
      <div class="resume-content">
        <h3 class="mb-0">{{ education.universityName }}</h3>
        <div class="subheading mb-3">{{ education.titleName }}</div>
        <p *ngIf="education.thesis">Thesis: "{{ education.thesis }}"</p>
        <div *ngIf="education.researchInterests">
          <p>Research interests:</p>
          <ul>
            <li *ngFor="let researchInterest of education.researchInterests">
              {{ researchInterest }}
            </li>
          </ul>
        </div>
        <h5>{{ education.studyProgram }}</h5>
        <p *ngIf="education.gpa">GPA: {{ education.gpa }}/10.00</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary"
          >{{ education.startingDate }} - {{ education.endingDate }}</span
        >
      </div>
    </div>
  </div>
</section>

import { Injectable } from "@angular/core";
import { Textbook } from "../models/textbook.model";

@Injectable({
  providedIn: "root",
})
export class TextbookService {
  private readonly _textbooks: Array<Textbook> = [];

  constructor() {
    this._textbooks = [
      new Textbook(
        "assets/textbook-thumbnails/pzv.png",
        "Programiranje za veb",
        "Advanced Web Development (workbook)",
        ["JavaScript", "TypeScript", "Angular", "Node.js (REST)", "MongoDB"],
        "assets/pdf/pzv.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/pbp.png",
        "Programiranje Db2 baza podataka",
        "Db2 Database Programming (workbook)",
        ["SQL", "Db2", "C/SQL", "Java/SQL (JDBC)", "Hibernate"],
        "https://matf-pbp.github.io/vezbe/"
      ),
      new Textbook(
        "assets/textbook-thumbnails/uvit.png",
        "Uvod u veb i internet tehnologije",
        "Introduction to Web and Internet Technologies (workbook)",
        ["HTML 5", "CSS 3", "JavaScript", "Node.js (MVC)", "MongoDB"],
        "https://matfuvit.github.io/UVIT/vezbe/knjiga/"
      ),
      new Textbook(
        "assets/textbook-thumbnails/ppj.png",
        "Prevođenje programskih jezika",
        "Programming Language Compilation (textbook)",
        [
          "Regular expressions",
          "Context-free grammars",
          "Automata theory",
          "Lexical analysis",
          "Syntactic analysis",
          "Semantic analysis",
        ],
        "https://matf-bg-ac-rs.gitlab.io/course-ppj/ppj-literatura/ppj.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/ip.png",
        "Istraživanje podataka",
        "Introduction to Data Mining (textbook)",
        [
          "Data",
          "KDA",
          "Classification",
          "Regression",
          "Neural networks",
          "Association rules",
          "Clustering",
        ],
        "assets/pdf/ip.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/is.png",
        "Informacioni sistemi",
        "Information systems (textbook)",
        [
          "Development methodologies",
          "Modeling techniques",
          "Risk analysis",
          "Security",
          "Architecture",
        ],
        "assets/pdf/is.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/kiaa2.png",
        "Konstrukcija i analiza algoritama 2",
        "Algorithm analysis 2 (workbook)",
        [
          "Geometric algorithms",
          "Data structure analysis",
          "Probabilistic algorithms",
          "Graph algorithms",
          "Reductions",
          "Search",
          "Parallel algorithms",
        ],
        "assets/pdf/kiaa2.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/mu.png",
        "Mašinsko učenje",
        "Machine Learning (workbook)",
        [
          "Regression",
          "Classification",
          "Instance-based algorithms",
          "Evaluation and model selection",
          "Deep learning",
          "Reinforcement learning",
        ],
        "assets/pdf/mu.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/ni.png",
        "Naučno izračunavanje",
        "Scientific Calculations & Application (workbook)",
        [
          "Function approximation",
          "Fourier transform",
          "Linear algebra",
          "Optimization",
        ],
        "assets/pdf/ni.pdf"
      ),
      new Textbook(
        "assets/textbook-thumbnails/ar.png",
        "Automatsko rezonovanje",
        "Automated reasoning (textbook)",
        [
          "Propositional calculus",
          "First-order logic",
          "First-order logic with equality",
          "SMT solvers",
        ],
        "assets/pdf/ar.pdf"
      ),
    ];
  }

  public get textbooks(): Array<Textbook> {
    return this._textbooks;
  }
}

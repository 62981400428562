import { Component, OnInit } from '@angular/core';
import {Software} from '../../models/software.model';
import {SoftwareService} from '../../services/software.service';

@Component({
  selector: 'app-softwares',
  templateUrl: './softwares.component.html',
  styleUrls: ['./softwares.component.css']
})
export class SoftwaresComponent implements OnInit {
  private readonly _softwares: Array<Software> = [];

  constructor(private _softwareService: SoftwareService) {
    this._softwares = this._softwareService.softwares;
  }

  ngOnInit() {
  }

  public get softwares(): Array<Software> { return this._softwares; }
}

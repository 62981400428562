<div class="card mb-3 mr-3">
  <div class="row no-gutters">
    <div class="col-md-4 d-flex align-items-center justify-content-center">
      <img [src]="software.imgUrl" class="card-img" style="max-width: 50%" [alt]="software.title">
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title">{{ software.title }}</h5>
        <ul class="list-group mb-3">
          <li *ngFor="let info of software.infos" class="list-group-item">{{ info }}</li>
        </ul>
        <a [href]="software.sourceUrl"
           class="btn btn-outline-info mb-3">View source code</a>

        <p class="card-text">
          <small class="text-muted">Development phase: </small> <span [ngClass]="software.getClassForDevPhase()">{{ software.getNameForDevPhase() }}</span>
        </p>
      </div>
    </div>
  </div>
</div>

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { AboutComponent } from "./about/about.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EducationComponent } from "./education/education.component";
import { ExperienceComponent } from "./experience/experience.component";
import { MainmenuComponent } from "./mainmenu/mainmenu.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ProjectsComponent } from "./projects/projects.component";
import { SkillsComponent } from "./skills/skills.component";
import { SoftwareComponent } from "./software/software.component";
import { SoftwaresComponent } from "./softwares/softwares.component";
import { jobsReducer } from "./state/jobs/jobs.reducer";
import { TextbookComponent } from "./textbook/textbook.component";
import { TextbooksComponent } from "./textbooks/textbooks.component";

@NgModule({
  declarations: [
    AppComponent,
    MainmenuComponent,
    AboutComponent,
    ExperienceComponent,
    EducationComponent,
    SkillsComponent,
    ProjectsComponent,
    TextbookComponent,
    SoftwareComponent,
    PageNotFoundComponent,
    TextbooksComponent,
    SoftwaresComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot({ jobs: jobsReducer }),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

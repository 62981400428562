export class Education {
  constructor(
    public universityName: string,
    public titleName: string,
    public studyProgram: string,
    public gpa: number,
    public startingDate: string,
    public endingDate: string,
    public thesis?: string,
    public researchInterests?: string[]) {
  }
}

<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
  <a class="navbar-brand js-scroll-trigger" routerLink="/">
    <span class="d-block d-lg-none">Ajzenhamer Nikola</span>
    <span class="d-none d-lg-block">
        <img class="img-fluid img-profile rounded-circle mx-auto mb-2" src="assets/img/profile.png" alt="">
      </span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link js-scroll-trigger" routerLink="/">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link js-scroll-trigger" routerLink="/experience">Experience</a>
      </li>
      <li class="nav-item">
        <a class="nav-link js-scroll-trigger" routerLink="/education">Education</a>
      </li>
      <li class="nav-item">
        <a class="nav-link js-scroll-trigger" routerLink="/skills">Skills</a>
      </li>
      <li class="nav-item">
        <a class="nav-link js-scroll-trigger" routerLink="/projects">Projects</a>
      </li>
    </ul>
  </div>
</nav>

<section
  @fade
  class="resume-section p-3 p-lg-5 d-flex align-items-center"
  id="projects"
>
  <div class="w-100">
    <h2 class="mb-5">Projects</h2>

    <app-textbooks></app-textbooks>

    <app-softwares></app-softwares>
  </div>
</section>

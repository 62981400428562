export enum DevPhase {
  COMPLETED,
  TEMP_SUSPENDED
}

export class Software {
  private readonly _imgUrl: string;
  private readonly _title: string;
  private readonly _infos: Array<string>;
  private readonly _sourceUrl: string;
  private readonly _devPhase: DevPhase;

  constructor(_imgUrl: string,
              _title: string,
              _infos: Array<string>,
              _sourceUrl: string,
              _devPhase: DevPhase) {
    this._imgUrl = _imgUrl;
    this._title = _title;
    this._infos = _infos;
    this._sourceUrl = _sourceUrl;
    this._devPhase = _devPhase;
  }

  public get imgUrl(): string { return this._imgUrl; }
  public get title(): string { return this._title; }
  public get infos(): Array<string> { return this._infos; }
  public get sourceUrl(): string { return this._sourceUrl; }

  public getClassForDevPhase(): string {
    switch (this._devPhase) {
      case DevPhase.COMPLETED:
        return 'badge badge-success';
      case DevPhase.TEMP_SUSPENDED:
        return 'badge badge-warning';
    }
  }

  public getNameForDevPhase(): string {
    switch (this._devPhase) {
      case DevPhase.COMPLETED:
        return 'Completed';
      case DevPhase.TEMP_SUSPENDED:
        return 'Temporarily suspended';
    }
  }
}

import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Job } from "../models/job.model";

@Injectable({
  providedIn: "root",
})
export class JobService {
  private readonly jobs: Job[] = [];

  constructor() {
    this.jobs.push(
      new Job("Backend Engineer", "ProGlove", "At ProGlove I develop a state-of-the-art serverless application powered by Amazon Web Services and Python.", null, null, "December 2022"),
      new Job(
        "AI Software Architect/Fullstack Developer",
        "CXera",
        "At CXera my job was developing microservice applications using MEAN stack and engineering various AI solutions throughout the application.",
        null,
        null,
        "November 2020",
        "November 2022",
      )
    );
    this.jobs.push(
      new Job(
        "Teaching Assistant",
        "University of Belgrade, Faculty of Mathematics",
        "As a member of the Department of Computer Science and Informatics, my job was to teach lab classes in computer science courses and to exercise research efforts in Optimization and Metaheuristics subfields.",
        'Courses that I have been teaching (websites in Serbian available at <a href="https://matf.nikolaajzenhamer.rs/kursevi">this page</a>)',
        [
          "[Undergraduate][Year 1] Programming 2",
          "[Undergraduate][Year 2] Introduction to Web and Internet Technologies",
          "[Undergraduate][Year 3] Database Programming",
          "[Undergraduate][Year 4] Software Development 1",
          "[Undergraduate][Year 4] Web Development",
          "[Graduate][Year 5] Software Development 2",
        ],
        "October 2018",
        "October 2022"
      )
    );
    this.jobs.push(
      new Job(
        "Analyst Programmer (Junior C++ Developer)",
        "IGT",
        "My job at IGT is to work with project managers, artists, game producers and testers in order to create highly entertaining games for virtual lottery terminals, with the emphasis on slot games.",
        null,
        null,
        "May 2017",
        "September 2018"
      )
    );
  }

  public getJobs(): Observable<Job[]> {
    return of(this.jobs);
  }
}

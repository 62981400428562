import { Component, OnInit } from "@angular/core";
import { Education } from "../../models/education.model";
import fadeAnimation from "../ui-util/animations/fade.animation";

@Component({
  selector: "app-education",
  templateUrl: "./education.component.html",
  styleUrls: ["./education.component.css"],
  animations: [fadeAnimation],
})
export class EducationComponent implements OnInit {
  educations: Education[];

  constructor() {
    this.educations = [
      new Education(
        "University of Belgrade, Faculty of Mathematics",
        "Master of Science",
        "Informatics",
        9.54,
        "October 2016",
        "September 2020",
        "Running time prediction of SAT solvers using graph neural networks"
      ),
      new Education(
        "University of Belgrade, Faculty of Mathematics",
        "Bachelor of Science",
        "Informatics",
        9.31,
        "September 2013",
        "September 2016"
      ),
    ];
  }

  ngOnInit() {}
}

import {Component, Input} from '@angular/core';
import {Software} from "../../models/software.model";

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.css']
})
export class SoftwareComponent {

  @Input('software')
  public _software: Software;

  constructor() { }

  public get software(): Software { return this._software; }

}

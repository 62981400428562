import { Component } from "@angular/core";
import fadeAnimation from "../ui-util/animations/fade.animation";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
  animations: [fadeAnimation],
})
export class ProjectsComponent {
  constructor() {}
}

<section
  @fade
  class="resume-section p-3 p-lg-5 d-flex align-items-center"
  id="skills"
>
  <div class="w-100">
    <h2 class="mb-5">Skills</h2>

    <div class="subheading mb-3">Programming Languages &amp; Tools</div>

    <p class="lead">Desktop &amp; cross-platform development</p>


    <ul class="list-inline dev-icons">
      <li class="list-inline-item">
        <i class="devicon-cplusplus-plain" title="C++"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-c-plain" title="C"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-qt-original" title="Qt"></i>
      </li>
    </ul>

    <p class="lead">Web development</p>

    <ul class="list-inline dev-icons">
      <li class="list-inline-item">
        <i class="devicon-html5-plain-wordmark" title="HTML5"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-css3-plain-wordmark" title="CSS3"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-bootstrap-plain" title="Bootstrap"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-javascript-plain" title="JavaScript"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-typescript-plain" title="TypeScript"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-angularjs-plain" title="Angular 2"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-nodejs-plain" title="Node.js"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-csharp-plain" title="C#"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-dot-net-plain-wordmark" title=".NET 5"></i>
      </li>
    </ul>

    <p class="lead">Database development</p>

    <ul class="list-inline dev-icons">
      <li class="list-inline-item">
        <i class="devicon-mysql-plain" title="SQL (MySQL)"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-microsoftsqlserver-plain-wordmark" title="SQL (SQL Server)"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-mongodb-plain" title="NoSQL (MongoDB)"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-redis-plain" title="NoSQL (Redis)"></i>
      </li>
    </ul>

    <p class="lead">AI development</p>

    <ul class="list-inline dev-icons">
      <li class="list-inline-item">
        <i class="devicon-python-plain-wordmark" title="Python"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-jupyter-plain-wordmark" title="Jupyter"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-anaconda-original" title="Anaconda"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-pytorch-original" title="PyTorch"></i>
      </li>
    </ul>

    <p class="lead">Tools &amp; platforms</p>

    <ul class="list-inline dev-icons">
      <li class="list-inline-item">
        <i class="devicon-vscode-plain" title="Visual Studio Code"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-visualstudio-plain" title="Visual Studio"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-jetbrains-plain" title="JetBrains"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-windows8-original" title="Windows"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-ubuntu-plain" title="Ubuntu"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-docker-plain" title="Docker"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-git-plain" title="git"></i>
      </li>
      <li class="list-inline-item">
        <i class="devicon-latex-original" title="LaTeX"></i>
      </li>
    </ul>

    <div class="subheading mb-3">Workflow</div>
    <ul class="fa-ul mb-0">
      <li>
        <i class="fa-li fa fa-check"></i>
        Object-Oriented Analysis &amp; Design (OOAD, UML, Domain-Driven Design)
      </li>
      <li>
        <i class="fa-li fa fa-check"></i>
        Design patterns
      </li>
      <li>
        <i class="fa-li fa fa-check"></i>
        Relational and NoSQL (Document) database design
      </li>
      <li>
        <i class="fa-li fa fa-check"></i>
        Architectural styles (Microservices, Clean, Event-driven, MV*, REST,
        N-tier)
      </li>
      <li>
        <i class="fa-li fa fa-check"></i>
        Continuous Integration, Continuous Delivery
      </li>
      <li>
        <i class="fa-li fa fa-check"></i>
        Software development methodologies (Agile, RAD, Waterfall)
      </li>
    </ul>
  </div>
</section>

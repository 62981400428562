import { Component, OnInit } from '@angular/core';
import {Textbook} from '../../models/textbook.model';
import {TextbookService} from '../../services/textbook.service';

@Component({
  selector: 'app-textbooks',
  templateUrl: './textbooks.component.html',
  styleUrls: ['./textbooks.component.css']
})
export class TextbooksComponent implements OnInit {

  private readonly _textbooks: Array<Textbook> = [];

  constructor(private _textbookService: TextbookService) {
    this._textbooks = this._textbookService.textbooks;
  }

  ngOnInit(): void {
  }

  public get textbooks(): Array<Textbook> { return this._textbooks; }

}

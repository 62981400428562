export class Job {
  constructor(
    public positionName: string,
    public companyName: string,
    public description: string,
    public responsibilityDescription: string,
    public responsibilities: string[],
    public startingDate: string,
    public endingDate?: string) {
    if (!endingDate) {
      this.endingDate = 'Present';
    }
  }
}

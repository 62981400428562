import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export default trigger("fade", [
  state("void", style({ opacity: 0 })),
  transition("void => *", [animate(1000)]),
  transition("* => void", [animate(1000)]),
]);

import {Component, Input} from '@angular/core';
import {Textbook} from '../../models/textbook.model';

@Component({
  selector: 'app-textbook',
  templateUrl: './textbook.component.html',
  styleUrls: ['./textbook.component.css']
})
export class TextbookComponent {

  @Input()
  public textbook: Textbook;

  constructor() { }

}

import { Injectable } from "@angular/core";
import { DevPhase, Software } from "../models/software.model";

@Injectable({
  providedIn: "root",
})
export class SoftwareService {
  private readonly _softwares: Array<Software> = [];

  constructor() {
    this._softwares = [
      new Software(
        "assets/software-thumbnails/sinergija.png",
        "Sinergija V2",
        [
          "API re-implementation of the information system for student organization OMIKRON",
          "Python 3, MongoDB, Eve",
          "Developers: Ajzenhamer Nikola",
        ],
        "https://github.com/ajzenhamernikola/sinergija-v2",
        DevPhase.COMPLETED
      ),
      new Software(
        "assets/software-thumbnails/gnnsat.png",
        "GNNSAT",
        [
          "SAT portfolio that utilises various graph neural networks",
          "Python, C++",
          "Developers: Ajzenhamer Nikola",
        ],
        "https://github.com/ajzenhamernikola/Master-Thesis",
        DevPhase.COMPLETED
      ),
      new Software(
        "assets/software-thumbnails/sinergija.png",
        "Sinergija",
        [
          "Information system for student organization OMIKRON",
          "PHP, MySQL, JavaScript, jQuery, Foundation",
          "Developers: Ajzenhamer Nikola, Bukurov Anja, Stanković Vojislav",
        ],
        "https://github.com/ajzenhamernikola/Sinergija",
        DevPhase.COMPLETED
      ),
      new Software(
        "assets/software-thumbnails/gia.png",
        "GiA",
        [
          "Graph problem-solving application",
          "C++, Qt",
          "Developers: Ajzenhamer Nikola, Doža Daniel",
        ],
        "https://github.com/ajzenhamernikola/GiA",
        DevPhase.TEMP_SUSPENDED
      ),
    ];
  }

  public get softwares(): Array<Software> {
    return this._softwares;
  }
}
